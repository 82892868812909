export default [
    {
        path: "/#servicios",
        text: "Servicios SEO",
    },
    {
        path: "/#estrategias",
        text: "Posicionamiento web",
    },
    {
        path: "/#about",
        text: "Contacto",
    },
  /*  {
        path: "/account",
        text: "Cuenta",
    },*/
]
