import React from 'react'

import {FaPhoneAlt, FaTwitterSquare,FaEnvelope} from 'react-icons/fa'
export default [
    {
        icon:<FaPhoneAlt/>,
        url:'tel:+34601463118',
        user: '601463118'
    },
    {
        icon:<FaEnvelope />,
        url:'mailto:info@eduardoasenjo.com',
        user:'info@eduardoasenjo.com'
    },
    {
        icon:<FaTwitterSquare/>,
        url:'https://twitter.com/phantomseo',
        user: '@PhantomSEO'
    }
   
]

