import React from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

const getData = graphql`
    query {
        site {
            siteMetadata {
                siteTitle: title
                siteDesc: description
                author
                siteUrl
                image
                twitterUsername
            }
        }
    }
`

const SEO = ({ title, description, index }) => {
    const { site } = useStaticQuery(getData)
    const {
        siteDesc,
        siteTitle,
        siteUrl,
        image,
        twitterUsername,
    } = site.siteMetadata
    return (
        <Helmet
            htmlAttributes={{ lang: "es" }}
            title={`${title} | ${siteTitle}`}
        >
      
            <meta name="description" content={description || siteDesc} />
            <meta name="image" content={`${siteUrl}${image}`}  />
            <meta name="robots" content={index} />
            {/* facebook card */}
            <meta property="og:url" content={siteUrl} />
            <meta property="og:type" content="website" />
            <meta property="og:title" content={siteTitle} />
            <meta property="og:description" content={siteDesc} />
            <meta property="og:image" content={`${siteUrl}${image}`} />
            <meta property="og:image:width" content="400" />
            <meta property="og:image:height" content="300" />

            {/* twitter card */}
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:creator" content={twitterUsername} />
            <meta name="twitter:title" content={siteTitle} />
            <meta name="twitter:description" content={siteDesc} />
            <meta name="twitter:image" content={`${siteUrl}${image}`} />

            {/* Microdata */}
            <script type="application/ld+json">
                {`
                  {
                    "@context": "http://schema.org",
                    "@type": "Organization",
                    "name": "Consultor SEO Eduardo Asenjo",
                    "url": "https://eduardoasenjo.com",
                    "logo": "https://ea-site-f32a2c.netlify.app/static/logo-1c7a2155b31a4435f9de1b1c7243c6bf.svg",
                    "potentialAction": {
                    "@type": "SearchAction",
                    "target": "",
                    "query-input": "required name=search_term_string"
                    },
                    "contactPoint" : [{
                    "@type" : "ContactPoint",
                    "telephone" : "+34601463118",
                    "contactType" : "customer support",
                    "areaServed" : "ES"
                    } ],
                    "sameAs" : []

                  }
                `}
            </script>


            <script type="application/ld+json">
                {`
                  {
                    "@context": "https://schema.org",
                    "@type": "LocalBusiness",
                    "image": "https://ea-site-f32a2c.netlify.app/static/logo-1c7a2155b31a4435f9de1b1c7243c6bf.svg",
                    "description": "Posicionamiento web en buscadores. Desarrollo y analítica web en Zaragoza.",
                    "name": "Consultor SEO Eduardo Asenjo",
                    "address": {
                      "@type": "PostalAddress",
                      "streetAddress": "C/ Más de las Matas, 20. Oficina 6." ,
                      "addressLocality": "Zaragoza",
                      "addressRegion": "Aragón",
                      "postalCode": "50014",
                      "addressCountry": "ES"
                    },
                  
                    "geo": {
                      "@type": "GeoCoordinates",
                      "latitude": 41.6639101,
                      "longitude": -0.8676777
                    },
                    "url": "https://www.eduardoasenjo.com",
                    "telephone": "+34601463118",
                    "openingHours": ["Mo,Tu,We,Th,Fr 10:00-14:00"]
                  }
                `}
            </script>




        </Helmet>
    )
}
export default SEO
