import React from "react"
import links from "../../constants/links"
import socialIcons from "../../constants/social-icons"
//import logo from "../../images/logo.svg"
//import logo from "../../images/ea-icon-144.svg"
import Logo from "../../assets/logo.svg"

//import { Link } from "gatsby"
import { Nav } from "react-bootstrap"
import Navbar from "react-bootstrap/Navbar"
//import { useStaticQuery, graphql } from "gatsby"




const NavbarRB = () => {
    return (
        <Navbar bg="light" expand="lg" >
            <Navbar.Brand href="/">
              {/*  <img src={logo} alt="Eduardo Asenjo logo" />*/}
                <Logo width="16rem" alt="Consultor SEO Eduardo Asenjo" aria-label="Inicio EA site"/>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="mr-auto">
                    {links.map((item, index) => {
                        return (
                            <Nav.Link key={index} href={item.path}>
                                {item.text}
                            </Nav.Link>
                        )
                    })}



                    
                </Nav>
            
                <div className="linksNav">
                    {socialIcons.map((item, index) => {
                    return ( 
                        <a
                            key={index}
                            href={item.url}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {item.icon}
                            {item.user}
                        </a>
                    )
                })}
                </div>
               
            </Navbar.Collapse>
           
        </Navbar>
    )
}

export default NavbarRB
