import React, { Suspense, lazy } from "react"
import "bootstrap/dist/css/bootstrap.min.css"
import "./primary-layout.css"

import Navbar from "../components/Common/NavbarRB"
//import Footer from "../components/Common/Footer"

//import ScrollToTopBtn from "../components/Common/ScrollToTop"
const ScrollToTopBtn = lazy(() => import("../components/Common/ScrollToTop"))
const Footer = lazy(() => import("../components/Common/Footer"))
const isSSR = typeof window === "undefined"
const PrimaryLayout = ({ children }) => {
    return (
        <main>
            <>
                {!isSSR && (
                    <Suspense fallback="">
                        {" "}
                        <ScrollToTopBtn />
                    </Suspense>
                )}
            </>
            <Navbar />
            {children}
            <>
                {!isSSR && (
                    <Suspense fallback='<footer />'>
                        {" "}
                        <Footer />
                    </Suspense>
                )}
            </>
        </main>
    )
}

export default PrimaryLayout
